import {gql} from '@apollo/client';

import {cartItemFragment, cartItemsFragment} from './cart-item-queries';
import {wicCartItemFragment} from './product-queries';

export const getCartsForReservationDetailsQuery = gql`
    query GetCartsForReservationDetails($customerId: Int!) {
        carts(customerId: $customerId) {
            isActive
            cartId
            fulfillmentType
            fulfillmentLocationId
            deliveryAddress {
                firstName
                lastName
                deliveryAddressId
                addressOne
                addressTwo
                city
                state
                zip
                locationType
                phoneNumber
                isVerified
                companyName
                fulfillmentLocations {
                    fulfillmentStore {
                        name
                        city
                        state
                        zip
                        address
                        storeId
                    }
                    fulfillmentLocationId
                    fulfillmentStoreId
                    fulfillmentStoreLocation {
                        locationId
                        ecommerceStatus
                    }
                }
            }
            pickupLocation {
                pickupLocationId
                pickupStoreId
                name
                address
                city
                state
                zip
                isActive
                latitude
                longitude
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
            store {
                storeId
                name
                address
                city
                state
                zip
                latitude
                longitude
            }
        }
    }
`;

export const getActiveCart = gql`
    query getActiveCart(
        $customerId: Int!
        $customerUuid: String!
        $clpeEnabled: Boolean! = false
        $wicEnabled: Boolean = false
        $locationIds: [ID!]
        $substitutionDataEnabled: Boolean! = false
        $includeFulfillmentTimes: Boolean! = true
    ) {
        customer(customerUuid: $customerUuid) {
            customerId
            customerUuid
            customerMemberships: customerMembershipsV2 {
                customerMembershipId
                isActive
                membership {
                    membershipId
                    membershipType
                }
            }
            fuelSaverCard {
                fuelSaverCardUuid
            }
            firstName
        }
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            customerId
            storeId
            fulfillmentExpiration
            fulfillmentId
            fulfillmentWindowStart
            fulfillmentWindowEnd
            fulfillmentType
            substitutionPreference
            vehicleColor
            vehicleType
            fulfillmentLocation {
                fulfillmentLocationId
                fulfillmentStoreId
                requireSeparateAlcoholOrder
                locationName
                canFulfillAlcohol
                fulfillmentFees {
                    feeWithMembership
                    feeWithoutMembership
                    orderMinimumAmount
                    duration
                    priorityType
                }
                fulfillmentTimes @include(if: $includeFulfillmentTimes) {
                    duration
                    fulfillmentScheduleId
                    preventAlcohol
                    windowStart
                    windowEnd
                    priorityType
                }
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
            ...ICartItems
            cartPromotions {
                cartPromotionId
                isApplied
                aislesOnlinePromotion {
                    aislesOnlinePromotionId
                    promoCode
                    description
                    threshold
                    amount
                    isPercentage
                    isActive
                    startDate
                    endDate
                    allowedUsages
                    isStackable
                    isFuelSaver
                    newCustomersOnly
                    isAvailableForAllOrderTypes
                    isAvailableForAllStores
                }
            }
            clpeCartPromotions @include(if: $clpeEnabled) {
                promotionNumber
                rewardType
                description
                rewardAmount
                cartItemIds
                rewardPercentage
            }
            clpeCartSavings {
                promotionNumber
                cartId
                cartItemId
                clpeCartSavingId
                rewardTypeId
                rewardAmount
                rewardPercentage
            }
            deliveryAddress {
                deliveryAddressId
                firstName
                lastName
                addressOne
                addressTwo
                city
                state
                zip
                latitude
                longitude
                locationType
                fulfillmentLocations {
                    fulfillmentLocationId
                    deliveryProviderNames
                }
            }
            pickupLocation {
                pickupLocationId
                name
                address
                city
                state
                zip
                isActive
                pickupLocationHasLocker
            }
            store {
                storeId
                name
                address
                city
                state
                zip
            }
        }
    }
    ${cartItemsFragment}
`;

export const getActiveWicCart = gql`
    query getActiveWicCart($customerId: Int!, $wicEnabled: Boolean = false) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            cartItems {
                ...IWicCartItemFragment
            }
        }
    }

    ${wicCartItemFragment}
`;

export const getCurrentCustomerInfo = gql`
    query getCurrentCustomerInfo($customerUuid: String!) {
        customer(customerUuid: $customerUuid) {
            customerId
            customerUuid
            customerMemberships: customerMembershipsV2 {
                customerMembershipId
                isActive
                membership {
                    membershipId
                    membershipType
                }
            }
            fuelSaverCard {
                fuelSaverCardUuid
            }
            firstName
        }
    }
`;

export const getStoreProductsForListItems = gql`
    query getStoreProductsForListItems(
        $productIds: [Int!]
        $page: Int
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
    ) {
        storeProducts(
            where: {storeId: $storeId, isActive: true}
            page: $page
            pageSize: 50
            whereProductIds: $productIds
        ) {
            page
            nextPage
            storeProducts {
                isAlcohol
                isWeighted
                productId
                storeProductId
                department {
                    departmentId
                }
                product {
                    productId
                    averageWeight
                    productLockers @include(if: $pickupLocationHasLocker) {
                        productLockerId
                        pickupLocationId
                        isLockerEligible
                    }
                    upc
                }
            }
        }
    }
`;

export const getActiveCartForGlobalNavigation = gql`
    query getActiveCartForGlobalNavigation(
        $customerId: Int!
        $clpeEnabled: Boolean! = false
        $substitutionDataEnabled: Boolean! = false
        $wicEnabled: Boolean = false
        $locationIds: [ID!]
    ) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            deliveryAddress {
                firstName
                deliveryAddressId
                addressOne
                city
                state
                zip
            }
            fulfillmentExpiration
            fulfillmentLocation {
                fulfillmentLocationId
                fulfillmentStoreId
                canFulfillAlcohol
                locationName
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
            fulfillmentType
            fulfillmentWindowEnd
            fulfillmentWindowStart
            pickupLocation {
                pickupLocationId
                name
            }
            store {
                storeId
                state
                name
            }
            storeId
            ...ICartItems
        }
    }
    ${cartItemsFragment}
`;

export const getActiveCartForCartItemCacheMutations = gql`
    query getActiveCartForCartItemCacheMutations(
        $customerId: Int!
        $clpeEnabled: Boolean! = false
        $substitutionDataEnabled: Boolean! = false
        $wicEnabled: Boolean = false
        $locationIds: [ID!]
    ) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            ...ICartItems
        }
    }
    ${cartItemsFragment}
`;

export const getActiveCartForReservationStepper = gql`
    query getActiveCartForReservationStepper($customerId: Int!) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            cartItems {
                cartItemId
                storeProduct {
                    storeProductId
                    isAlcohol
                }
            }
            fulfillmentType
            deliveryAddress {
                deliveryAddressId
                addressOne
                addressTwo
                city
                state
                zip
            }
            fulfillmentId
            fulfillmentWindowStart
            fulfillmentWindowEnd
            fulfillmentExpiration
            fulfillmentLocation {
                canFulfillAlcohol
                fulfillmentLocationId
                requireSeparateAlcoholOrder
                fulfillmentStore {
                    storeId
                    name
                    nickname
                    address
                    city
                    state
                    zip
                }
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
            pickupLocation {
                pickupLocationId
                fulfillmentLocationId
                name
                fulfillmentStoreId
                fulfillmentType
                pickupLocationHasLocker
                requireSeparateAlcoholOrder
                passcode
                isActive
                pickupStoreId
            }
        }
    }
`;

export const getActiveCartForReservationButton = gql`
    query getActiveCartForReservationButton($customerId: Int!) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            fulfillmentId
            fulfillmentWindowStart
            fulfillmentWindowEnd
            fulfillmentType
            fulfillmentExpiration
            fulfillmentLocation {
                fulfillmentLocationId
                fulfillmentStore {
                    storeId
                    name
                    zip
                    state
                    city
                }
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
                locationName
            }
            deliveryAddress {
                deliveryAddressId
                addressOne
                addressTwo
                city
                deliveryAddressId
                state
                zip
            }
        }
    }
`;

/*
 * This query will be used on every single page in the page with customer data wrapper
 * Only the minimum necessary fields needed for every page should be included
 */
export const getBasicCustomerInformation = gql`
    ${cartItemFragment}

    query GetBasicCustomerInformation(
        $customerId: Int!
        $customerUuid: String!
        $includeProductCardData: Boolean = false
        $clpeEnabled: Boolean = false
        $substitutionDataEnabled: Boolean = false
        $wicEnabled: Boolean = false
        $locationIds: [ID!]
    ) {
        customer(customerUuid: $customerUuid) {
            customerId
            customerUuid
            firstName
            fuelSaverCard {
                fuelSaverCardUuid
            }
        }
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            customerId
            deliveryAddressId
            storeId
            fulfillmentType
            pickupLocation {
                pickupLocationId
                pickupLocationHasLocker
            }
            fulfillmentLocation @include(if: $includeProductCardData) {
                fulfillmentLocationId
                canFulfillAlcohol
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
            cartItems @include(if: $includeProductCardData) {
                ...ICartItem
            }
        }
    }
`;

export const getOrderMinimumForActiveCart = gql`
    query getOrderMinimumForCustomer($customerId: Int!) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            fulfillmentLocationId
            fulfillmentLocation {
                fulfillmentLocationId
                fulfillmentFees {
                    orderMinimumAmount
                }
            }
        }
    }
`;

export const getDailyDealsForCartBanner = gql`
    query getDailyDealsForCartBanner($currentDate: DateTime!) {
        allCmsCartCheckoutWebAndMobileCartBanner {
            cartBannerStatus
            cartBannerVariant
            cartBannerTextRaw
        }
        allCmsMarketingDealsDailyDealDailyConfig(
            sort: {_updatedAt: DESC}
            where: {publishedAt: {lte: $currentDate}, endAt: {gte: $currentDate}, _: {is_draft: false}}
        ) {
            configurationTitle
            webLink
            cartText {
                textRaw
            }
        }
    }
`;
