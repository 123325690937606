import {
    SET_ADDRESS_SUGGESTIONS_FAILURE,
    SET_ADDRESS_SUGGESTIONS_REQUEST,
    SET_ADDRESS_SUGGESTIONS_SUCCESS
} from '../../action-types';

import transformAddressValidationToAddressSuggestions from './util/transformAddressValidationToAddressSuggestions';

export const loadAddressSuggestionsForAddress = (payload) => {
    return {
        fetch: {
            onFailure: (dispatch, getState, error) => {
                error.response.json().then((response) => {
                    dispatch({
                        saveAddressErrorStatus: response.statusCode,
                        type: SET_ADDRESS_SUGGESTIONS_FAILURE
                    });
                });
            },
            onSuccess: (dispatch, getState, data) => {
                const transformedData = transformAddressValidationToAddressSuggestions(data);

                dispatch({
                    addressSuggestions: transformedData,
                    type: SET_ADDRESS_SUGGESTIONS_SUCCESS
                });
            },
            options: {
                body: JSON.stringify({
                    address: {
                        addressLines: [payload?.addressOne, ...(payload?.addressTwo ? [payload.addressTwo] : [])],
                        city: payload?.city,
                        state: payload?.state,
                        zipcode: payload?.zip
                    }
                }),
                method: 'POST'
            },
            url: '/aisles-online/api/address-validation'
        },
        type: SET_ADDRESS_SUGGESTIONS_REQUEST
    };
};
